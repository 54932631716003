import css from 'bundle-text:./gallery.css';
import { cloneElement, type ComponentChildren, isValidElement, toChildArray, type VNode } from 'preact';
import register from 'preact-custom-element';
import { useEffect, useRef, useState } from 'preact/hooks';

const HISTORY_METHODS_TO_PATCH = ['pushState', 'replaceState'] as const;
const HISTORY_NAVIGATION_EVENT = '_historynavigate';
for (const method of HISTORY_METHODS_TO_PATCH) {
	const originalHistoryMethod = history[method];
	const replacementMethod = (...args: Parameters<typeof originalHistoryMethod>) => {
		originalHistoryMethod.apply(history, args);
		dispatchEvent(new CustomEvent(HISTORY_NAVIGATION_EVENT));
	}
	history[method] = replacementMethod;
}

history.pushState

const queryOnPageLoad = new URL(location.href).searchParams;
function useQuery() {
	const [query, setQuery] = useState(queryOnPageLoad);

	useEffect(() => {
		function handleNavigation() {
			setQuery(new URL(location.href).searchParams);
		}

		addEventListener('popstate', handleNavigation);
		addEventListener(HISTORY_NAVIGATION_EVENT, handleNavigation);

		return () => {
			removeEventListener('popstate', handleNavigation);
			removeEventListener(HISTORY_NAVIGATION_EVENT, handleNavigation);
		};
	}, []);

	function updateQuery(patch: object, replace = false) {
		const url = new URL(location.href);
		for (const [key, value] of Object.entries(patch) as [string, unknown][]) {
			if (value === null) {
				url.searchParams.delete(key);
			} else {
				url.searchParams.set(key, String(value));
			}
		}

		const method = replace ? 'replaceState' : 'pushState';
		history[method](null, '', url);
	}

	const queryHasntChanged = query === queryOnPageLoad;

	return [query, updateQuery, queryHasntChanged] as const;
}

function pathFromUrl(url: string | URL) {
	url = new URL(url, location.origin);
	return url.href.slice(url.origin.length);
}

function extractImgs(
	children: ComponentChildren,
	_imgs: VNode<{ src: string }>[] = [],
) {
	const childrenArray = toChildArray(children);
	for (let child of childrenArray) {
		if (isValidElement(child)) {
			if(child.type === 'img' && 'src' in child.props && typeof child.props['src'] === 'string') {
				_imgs.push(child as typeof _imgs[number]);
			} else {
				extractImgs(child.props.children, _imgs);
			}
		}
	}
	return _imgs;
}

function Gallery({
	children,
}: {
	children: ComponentChildren;
}) {
	const [query, updateQuery] = useQuery();
	const imgs = extractImgs(children);

	const activeSrc = query.get('img');
	const activeImg = imgs.find(img => {
		return pathFromUrl(img.props.src) === activeSrc;
	});
	const activeIndex = activeImg ? imgs.indexOf(activeImg) : -1;

	function handleThumbnailClick(event: MouseEvent) {
		if (!(event.currentTarget instanceof HTMLAnchorElement)) return;
		event.preventDefault();
		updateQuery({ img: pathFromUrl(event.currentTarget.href)});
	}

	function closeDialog() {
		updateQuery({ img: null });
	}

	function activate(d: number) {
		const nextImg = imgs.at((activeIndex + d) % imgs.length);
		if (!nextImg) return;
		updateQuery({ img: pathFromUrl(nextImg.props.src) }, true);
	}

	return (
		<div class="film-strip">
			<style>{css}</style>

			<ol>
				{imgs.map(img => {
					return (
						<li>
							<a href={img.props.src} onClick={handleThumbnailClick}>
								{cloneElement(img, { loading: 'lazy' })}
							</a>
						</li>
					);
				})}
			</ol>

			{activeImg && (
				<Dialog
					onPrevious={() => activate(-1)}
					onNext={() => activate(+1)}
					onDismiss={closeDialog}
				>
					{cloneElement(activeImg, { srcset: activeImg.props.src })}
				</Dialog>
			)}
		</div>
	);
}

function Dialog({
	children,
	onPrevious,
	onNext,
	onDismiss,
}: {
	children: ComponentChildren;
	onPrevious: () => void;
	onNext: () => void;
	onDismiss: () => void;
}) {
	const dialog = useRef<HTMLDialogElement>(null);
	const previousButton = useRef<HTMLButtonElement>(null);
	const nextButton = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		dialog.current?.showModal();
	}, []);

	function handlePointer(event: PointerEvent) {
		if (event.target === dialog.current) {
			onDismiss();
		}
	}

	function handleKeyDown(event: KeyboardEvent) {
		if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) return;

		if (event.key === 'ArrowLeft') {
			onPrevious();
			previousButton.current?.focus();
		}

		if (event.key === 'ArrowRight') {
			onNext();
			nextButton.current?.focus();
		}
	}

	return (
		<dialog
			ref={dialog}
			onPointerDown={handlePointer}
			onCancel={onDismiss}
			onKeyDown={handleKeyDown}
		>
			<button
				type="button"
				class="close-button"
				title="Close gallery"
				onClick={onDismiss}
			>
				&times;
			</button>

			{children}

			<button
				ref={previousButton}
				type="button"
				class="previous-button"
				title="Previous"
				onClick={onPrevious}
			>
				◀
			</button>

			<button
				ref={nextButton}
				type="button"
				class="next-button"
				title="Next"
				onClick={onNext}
			>
				▶
			</button>
		</dialog>
	);
}

register(Gallery, 'ab-gallery', [], { shadow: true });
